import { Component, inject } from '@angular/core';
import { communityDataToken } from '../community-panel/community-data';
import { ImpactTradeCommunityDataService } from './impact-trade.service';

@Component({
  template: ` <app-community-panel></app-community-panel> `,
  providers: [
    {
      provide: communityDataToken,
      useClass: ImpactTradeCommunityDataService,
    },
  ],
})
export class ImpactTradeCommunity {
  data = inject(communityDataToken);
  ngOnInit() {
    console.log(this.data);
  }
}
