<div
  class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-2"
>
  <h2>{{ infoIniciativas.title }}</h2>
  <div class="link-crear" *ngIf="infoIniciativas.linkProponer">
    <a [href]="infoIniciativas.linkProponer" target="_blank"
      ><span> Proponer una iniciativa!</span><span>&rarr;</span></a
    >
  </div>
</div>
<p>
  {{ infoIniciativas.description }}
</p>
<div
  *ngIf="elementos$.length > 0"
  class="container d-flex flex-wrap justify-content-between"
>
  <article class="mb-4" *ngFor="let elemento of elementos$">
    <a
      class="card-iniciativa"
      routerLink="iniciativas-detail/{{ elemento.id }}"
    >
      <div class="">
        <div class="logo">
          <img
            *ngIf="elemento['listadoImage']"
            src="{{ img_base }}{{ elemento['listadoImage'] }}"
            alt="{{ elemento.nombre }}"
          />
          <img
            *ngIf="!elemento['listadoImage']"
            src="/assets/img/imgs/default.jpg"
            alt="{{ elemento.nombre }}"
          />
        </div>
        <div class="info">
          <h4 class="fs-5 text-center mt-1">{{ elemento.nombre }}</h4>
          <p>{{ elemento.copete }}</p>
        </div>
      </div>
    </a>
  </article>
</div>
