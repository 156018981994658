<section class="filters-section">
  <div class="filters-container shadow">
    <form [formGroup]="filtro">
      <form-group>
        <label class="descCampo" for="nombre">Nombre </label>
        <input
          type="text"
          id="nombre"
          formControlName="nombre"
          class="form-control"
        />
      </form-group>
      <form-group>
        <label class="descCampo" for="copete">Empresa / Experiencia </label>
        <input
          type="text"
          id="copete"
          formControlName="copete"
          class="form-control"
        />
      </form-group>
      <!-- <form-group appearance="fill">
        <label for="dimension">Dimensión</label>
        <select
          id="dimension"
          formControlName="dimension"
          class="form-control"
          placeholder="Dimensión"
        >
          <option value="0" selected>Todas</option>
          <option *ngFor="let d of dimensionList" [ngValue]="d.id">
            {{ d.nombre }}
          </option>
        </select>
      </form-group> -->

      <div class="filters-actions">
        <button (click)="filtrar(false)">Buscar</button>
        <button (click)="resetear()">Borrar filtros</button>
      </div>
    </form>
  </div>
</section>
<div class="empresas-asociadas">
  <div class="container contenedor-empresas">
    <article *ngFor="let elemento of elementos$">
      <div class="empresa">
        <div class="logo">
          <img
            *ngIf="elemento['listadoImage']"
            src="{{ img_base }}{{ elemento['listadoImage'] }}"
            alt="{{ elemento.nombre }}"
          />
          <img
            *ngIf="!elemento['listadoImage']"
            src="/assets/img/imgs/default.jpg"
            alt="{{ elemento.nombre }}"
          />
          <div class="boton-oculto">
            <a (click)="openModal(elemento.id)">Más información</a>
          </div>
        </div>
        <h4>{{ elemento.nombre }}</h4>
      </div>
    </article>
  </div>
  <button *ngIf="this.elementos$.length < totalItems" class="load-more" (click)="filtrar(true)">Cargar más</button>
</div>
