<section>


  <div class="about-us">

    <div class="container">
      <h1>Nosotros</h1>
      <div class="about-us-texts">
        <p>
          Somos un colectivo multidisciplinario con la capacidad de unir
          tecnología y humanismo.<br />
          Combinamos saberes y experiencia en tecnología, medicina,
          neurociencias, psicología, educación e investigación.<br />
          <strong>Nuestros pilares:</strong> la sinergia y la colaboración en
          sincronía hacia un propósito común.
        </p>
      </div>
    </div>

  </div>
  <ul *ngIf="esSuscriptor" class="tabs-container">
    <li (click)="onTabClick('Empresas');focusTab($event)" [class.active]="activeTab == 'Empresas'" class="tab-link">
      <i class="bi bi-building-fill">Empresas</i>
    </li>
    <li *ngIf="esSuscriptor" (click)="onTabClick('Personas');focusTab($event)" [class.active]="activeTab == 'Personas'"
      class="tab-link"><i class="bi bi-person-circle"></i> Personas</li>
  </ul>

  <div *ngIf="activeTab == 'Empresas'">
    <app-empresas></app-empresas>
  </div>
  <div *ngIf="activeTab == 'Personas'">
    <app-personas></app-personas>
  </div>

</section>
