<section>
  <aside>
    <h2><span>Novedades </span>Futuras</h2>
  </aside>
  <main>
    <swiper-container
      id="swiper-container-future"
      class="swiper-container"
      navigation="{
            nextEl: '.button-next',
            prevEl: '.button-prev'
          }"
      pagination="false"
      slides-per-view="2.1"
      init="false"
    >
      <swiper-slide *ngFor="let elemento of eventosFuturos$" class="card">
        <span
          *ngIf="elemento.id"
          routerLink="/news-detail/{{ elemento.id }}"
        ></span>
        <a href="/news-detail/{{ elemento.id }}">
          <div class="image__container">
            <img
              *ngIf="elemento['detailImage']"
              [src]="img_base + elemento['detailImage']"
              alt=""
            />
          </div>
          <div class="data__container">
            <div class="first__data">
              <h5>{{ elemento.nombre }}</h5>
              <p *ngIf="elemento.categorias | categoriaFilter : 'ods'">
                ODS: {{ elemento.categorias | categoriaFilter : "ods" }}
              </p>
              <p>{{ elemento.fechaInfoInicio | date : "d MMM. yyyy" }}</p>
            </div>
          </div>
        </a>
      </swiper-slide>
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </swiper-container>
  </main>
</section>
