import { Component, Injectable, Type } from '@angular/core';
import { CommunityPanelParticipantesComponent } from './participantes/participantes.component';
import { CommunityPanelIniciativasComponent } from './iniciativas/iniciativas.component';
import { CommunityPanelAgendaComponent } from './agenda/agenda.component';
import { CommunityPanelFotosComponent } from './fotos/fotos.component';
import { CommunityPanelCompromisosComponent } from './compromisos/compromisos.component';

@Injectable({ providedIn: 'root' })
export class ContentService {
  contents = {
    participantes: CommunityPanelParticipantesComponent,
    iniciativas: CommunityPanelIniciativasComponent,
    agenda: CommunityPanelAgendaComponent,
    fotos: CommunityPanelFotosComponent,
    compromisos: CommunityPanelCompromisosComponent,
  };
  getContents() {
    return this.contents;
  }
}
