import { Component } from '@angular/core';

@Component({
  selector: 'app-proyectos-y-programas',
  templateUrl: './proyectos-y-programas.component.html',
  styleUrls: ['./proyectos-y-programas.component.scss']
})
export class ProyectosYProgramasComponent {

}
