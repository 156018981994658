
import { CUSTOM_ELEMENTS_SCHEMA, Component} from '@angular/core';
import { ElementService } from 'src/app/shared/services/element.service';
import { Elemento } from 'src/app/shared/models/elemento.model';
import { environment } from 'src/environments/environment';
import { ElementoFiltro } from 'src/app/shared/models/elementoFiltro.model';


@Component({
  selector: 'app-carrousel-one',
  templateUrl: './carrousel-one.component.html',
  styleUrls: ['./carrousel-one.component.scss'],

})
export class CarrouselOneComponent{

  constructor(private elementService: ElementService) { }

  img_base = environment.img_base;

  pageIndex = 0;
  desplazamiento = 0;
  limite = 9;
  totalItems = 0;

  elementos$: Elemento[] = [];

  ngOnInit() {
    this.filtrar();
  }


  public filtrar(): void {

    var filtroBanners: ElementoFiltro = new ElementoFiltro();
    filtroBanners.estado = "activo";
    filtroBanners.modulo = "Carousel_banners";

    this.elementService.getAll(this.limite , this.desplazamiento, filtroBanners, 'orden-asc').subscribe(
      data => {
          (data as any)['elementos'].forEach((element: Elemento) => {
            this.elementos$.push(element);
          });

        this.totalItems = (data as any)["total"];
      });
      this.pageIndex++;
  }

}
