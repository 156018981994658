
<section>
    <div>
        <h1>
            Propósito
        </h1>
        <p>
            Fibras es un ecosistema donde personas, empresas y organizaciones se unen para apoyar y acelerar proyectos e
            ideas utilizando la tecnología como plataforma para generar impacto social en las siguientes dimensiones:
        </p>
        <img src="/assets/img/imgs/dimensiones-es.png" alt="">
    </div>
</section>
