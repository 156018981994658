import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Compromiso } from '../models/compromiso.model';

@Injectable({
  providedIn: 'root',
})
export class CompromisoService {
  persons: any[] = [];
  companies: any[] = [];
  applications: any[] = [];

  constructor(private httpClient: HttpClient) {}
  public getCompromisos(
    option: number | null,
  ): Observable<{ commitments: Compromiso[] }> {
    let params = new HttpParams();
    let opcion = '';

    switch (option) {
      case 1:
        opcion = '?appliesTo=Aplicaciones&community=Fibras';
        break;
      case 2:
        // Personas o Empresas
        opcion = '&community=Fibras';
        break;
      default:
        break;
    }
    return this.httpClient
      .get<{
        commitments: any;
      }>(environment.api_fibras + 'commitments' + opcion, { params })
      .pipe(
        map(response => ({
          commitments: response.commitments
            ? response.commitments.sort(
                (a: { title: string }, b: { title: any }) =>
                  a.title.localeCompare(b.title),
              )
            : [],
        })),
      );
  }

  public async getCompromisosPorComunidad(communityName: string) {
    let params = new HttpParams();
    return lastValueFrom(
      this.httpClient
        .get<{
          commitments: any;
        }>(
          environment.api_fibras +
            'commitments' +
            `?community=${communityName}`,
          { params },
        )
        .pipe(
          map(response => ({
            commitments: response.commitments
              ? response.commitments.sort(
                  (a: { title: string }, b: { title: any }) =>
                    a.title.localeCompare(b.title),
                )
              : [],
          })),
        ),
    );
  }

  public getCompromiso(code: string): Observable<{ compromiso: Compromiso[] }> {
    return this.httpClient.get<{ compromiso: Compromiso[] }>(
      environment.api_fibras + 'commitments?code=' + code,
    );
  }

  private getPersonas(communityName: string): Observable<any> {
    return this.httpClient.get<any>(
      environment.api_fibras + `persons?community=${communityName}`,
    );
  }

  private getCompanies(communityName: string): Observable<any> {
    return this.httpClient.get<any>(
      environment.api_fibras + `companies?community=${communityName}`,
    );
  }

  private getAllApplications(): Observable<any> {
    return this.httpClient.get<any>(environment.api_fibras + `applications`);
  }

  private getAllCommittments(): Observable<any> {
    return this.httpClient.get<any>(environment.api_fibras + `commitments`);
  }

  private getAllPersons(): Observable<any> {
    return this.httpClient.get<any>(environment.api_fibras + `persons`);
  }

  private getAllCompanies(): Observable<any> {
    return this.httpClient.get<any>(environment.api_fibras + `companies`);
  }

  public async getAll(): Promise<void> {
    const { persons } = await lastValueFrom(this.getAllPersons());
    const { companies } = await lastValueFrom(this.getAllCompanies());
    const { applications } = await lastValueFrom(this.getAllApplications());

    this.persons = persons;
    this.companies = companies;
    this.applications = applications;
  }

  public async getApplicationsPorCompromiso(code: string): Promise<any> {
    const filteredApplications = this.applications.filter((app: any) => {
      const justNumbers = app.commitments.map((c: any) =>
        Number(c.commitmentCode),
      );
      return justNumbers?.includes(Number(code));
    });

    console.log(filteredApplications);
    return { applications: filteredApplications };
  }

  public async getMembersPorCompromiso(
    communityName: string,
    code: string,
  ): Promise<any> {
    const filteredPersons = [
      ...this.persons?.map((p: any) => ({ ...p, type: 'Persona' })),
      ...this.companies?.map((c: any) => ({
        ...c,
        type: 'Empresa',
        firstName: c.businessName,
      })),
    ].filter((persona: any) =>
      persona?.commitments
        ?.map((c: any) => c.commitmentCode)
        ?.includes(Number(code)),
    );

    return { persons: filteredPersons };
  }

  public getCompanyNameById(id: number): string {
    return this.companies.find((c: any) => c.id === id)?.businessName;
  }
}
