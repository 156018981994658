import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Elemento } from 'src/app/shared/models/elemento.model';
import { ElementoFiltro } from 'src/app/shared/models/elementoFiltro.model';
import { ElementService } from 'src/app/shared/services/element.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-iniciativa-detail',
  templateUrl: './iniciativa-detail.component.html',
  styleUrls: ['./iniciativa-detail.component.scss'],
})
export class IniciativaDetailComponent {
  idElemento!: string;
  elemento!: Elemento;
  categoria1: string = '';
  categoria2!: string;
  categoria3!: string;

  img_base = environment.img_base;

  constructor(
    private elementService: ElementService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.idElemento = this.route.snapshot.params['id'];
      console.log(this.idElemento);
      // Informaciones
      var filtroDetalle: ElementoFiltro = new ElementoFiltro();
      filtroDetalle.id = Number.parseInt(this.idElemento);
      this.elementService.getAll(0, 0, filtroDetalle, '').subscribe(data => {
        this.elemento = (data as any)['elementos'][0];
        if (this.elemento.fechaInfoInicio != null) {
          this.elemento.fechaInfoInicio = new Date(
            this.elemento.fechaInfoInicio,
          );

          if (!!this.elemento.categorias) {
            this.elemento.categorias.forEach(categoriaElemento => {
              if (categoriaElemento.categoria.padre.orden == 1) {
                this.categoria1 += categoriaElemento.categoria.codigo;
              }
              if (categoriaElemento.categoria.padre.orden == 2) {
                this.categoria2 = categoriaElemento.categoria.nombre;
              }
              if (categoriaElemento.categoria.padre.orden == 3) {
                this.categoria3 = categoriaElemento.categoria.nombre;
              }
            });
          }
        }
      });
    });
  }
}
