import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewerComponent } from '../../../../../../shared/components/viewer/viewer.component';

@Component({
  selector: 'app-fotos',
  templateUrl: './fotos.component.html',
  styleUrls: ['./fotos.component.scss'],
})
export class CommunityPanelFotosComponent {
  galleryId = 'impact-trade-gallery';
  items: { src: string; thumb: string }[] = [];
  constructor(private modalService: NgbModal) {}

  ngOnInit() {
    this.items = [
      {
        src: '../../../../../../../assets/img/imgs/impact-trade/galeria/1710806038520.jpg',
        thumb: '',
      },
      {
        src: '../../../../../../../assets/img/imgs/impact-trade/galeria/1710806038549.jpg',
        thumb: '',
      },
      {
        src: '../../../../../../../assets/img/imgs/impact-trade/galeria/1710806038571.jpg',
        thumb: '',
      },
      {
        src: '../../../../../../../assets/img/imgs/impact-trade/galeria/1710806038590.jpg',
        thumb: '',
      },
      {
        src: '../../../../../../../assets/img/imgs/impact-trade/galeria/1710806038607.jpg',
        thumb: '',
      },
      {
        src: '../../../../../../../assets/img/imgs/impact-trade/galeria/1710806038625.jpg',
        thumb: '',
      },
      {
        src: '../../../../../../../assets/img/imgs/impact-trade/galeria/1710806038646.jpg',
        thumb: '',
      },
      {
        src: '../../../../../../../assets/img/imgs/impact-trade/galeria/1710806038665.jpg',
        thumb: '',
      },
      {
        src: '../../../../../../../assets/img/imgs/impact-trade/galeria/1710806038684.jpg',
        thumb: '',
      },
      {
        src: '../../../../../../../assets/img/imgs/impact-trade/galeria/1710806038700.jpg',
        thumb: '',
      },
      {
        src: '../../../../../../../assets/img/imgs/impact-trade/galeria/_storage_emulated_0_Android_data_com.miui.gallery_cache_SecurityShare_1710806038497.jpg',
        thumb: '',
      },
      {
        src: '../../../../../../../assets/img/imgs/impact-trade/galeria/Foto de Juan Ignacio - Chama.jpg',
        thumb: '',
      },
      {
        src: '../../../../../../../assets/img/imgs/impact-trade/galeria/ImpactTrade2024_0315_130109_0518_@albertobrescia.ph.jpg',
        thumb: '',
      },
      {
        src: '../../../../../../../assets/img/imgs/impact-trade/galeria/ImpactTrade2024_0315_151429_6865_@albertobrescia.ph.jpg',
        thumb: '',
      },
      {
        src: '../../../../../../../assets/img/imgs/impact-trade/galeria/ImpactTrade2024_0315_152702_6931_@albertobrescia.ph.jpg',
        thumb: '',
      },
    ];
  }

  openModal(index: number) {
    const modalRef = this.modalService.open(ViewerComponent, {
      centered: true,
      size: 'xl',
      modalDialogClass: 'photo-viewer',
    });
    modalRef.componentInstance.index = index;
    modalRef.componentInstance.images = this.items;
  }
}
