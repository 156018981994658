import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs';
import { Comunidad } from 'src/app/modules/communities/types';
import { Compromiso } from 'src/app/shared/models/compromiso.model';
import { CompromisoService } from 'src/app/shared/services/compromiso.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-community-commitments',
  templateUrl: './community.component.html',
  styleUrls: ['./community.component.scss'],
})
export class CommunityCommitmentsComponent implements OnInit {
  compromisosList: Compromiso[] = [];
  id?: string;
  community!: Comunidad;
  img_base = environment.img_base;

  currentFilter?: string;
  image?: string;
  info!: any;
  constructor(
    private compromisoService: CompromisoService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    const nav = this.router.getCurrentNavigation();
    this.info = nav?.extras.state;
  }

  ngOnInit(): void {
    this.community = this.route.snapshot.data['community'];
    this.loadCompromisos();
  }

  async loadCompromisos(): Promise<void> {
    this.compromisosList = (
      await this.compromisoService.getCompromisosPorComunidad(
        this.community.fuente,
      )
    ).commitments;
  }

  setFilter(community: string) {
    this.currentFilter = community;
  }
}
