import { Component, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { JwtPayload } from 'jwt-decode';
import { KeycloakService } from 'keycloak-angular';
import { LayoutService } from './layout.service';
import { SuscripcionService } from './shared/services/suscripcion.service';
import { CompromisoService } from './shared/services/compromiso.service';

export interface JWTContent extends JwtPayload {
  memberId?: number;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'fibras';
  compromisoService = inject(CompromisoService);

  constructor(
    private layoutService: LayoutService,
    private router: Router,
  ) {}

  async ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const currentRoute = event.urlAfterRedirects;

        const isButtonActive = ['/', '/market'].includes(currentRoute);
        this.layoutService.setDonateButtonActive(isButtonActive);
      }
    });

    await this.compromisoService.getAll();
  }
}
