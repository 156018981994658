<main>
  <div class="appDetailsContent">
    <!-- <div class="breadcrumb">
    <div class="container">
      <a [routerLink]="'/'" i18n="Home">Home</a>
      <span> / </span> <span i18n="Manage">Manage</span> <span> / </span> <span i18n="PaymentsRouterLink">Payments</span>
    </div>
  </div> -->
  </div>

  <div class="container">
    <div class="row">
      <div class="col-12 mt-5 mb-4">
        <h1 class="mat-typography__underscore" i18n="PaymentsTitlePayments">
          Pagos
        </h1>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-sm">
          <div class="filtro">
            <label class="descCampo" i18n="PaymentType | Filtro Tipo en Pagos"
              >Tipo</label
            >
            <select class="campo form-control" [(ngModel)]="tipo">
              <option
                value="TODOS"
                i18n="AllOptionType | Opcion Todos filtro tipo en pagos"
              >
                Todos
              </option>
              <option
                value="FACTURA"
                i18n="InvoiceOption | Opcion Facturas filtro tipo en pagos"
              >
                Factura
              </option>
              <option
                value="ORDEN"
                i18n="OrderOption | Opcion Orden filtro tipo en pagos"
              >
                Orden
              </option>
            </select>
          </div>
        </div>
        <div class="col-sm">
          <div class="filtro">
            <label
              class="descCampo"
              i18n="PaymentStatus | Filtro Estado en Pagos"
              >Estado</label
            >
            <select class="campo form-control" [(ngModel)]="estado">
              <option
                value="TODOS"
                i18n="AllOptionStatus | Opcion Todos filtro estado en pagos"
              >
                Todos
              </option>
              <option
                value="PENDIENTE"
                i18n="PendingStatus | Opcion Pendiente filtro estado en pagos"
              >
                Pendiente
              </option>
              <option
                value="PAGADO"
                i18n="PayedStatus | Opcion Pagado filtro estado en pagos"
              >
                Pagado
              </option>
              <option
                value="EN_PROCESO"
                i18n="InProcessStatus | Opcion EnProceso filtro estado en pagos"
              >
                En Proceso
              </option>
              <option
                value="NO_AUTORIZADO"
                i18n="NoAuthorizedStatus | Opcion NoAutorizado filtro estado en pagos"
              >
                No Autorizado
              </option>
              <option
                value="ERROR"
                i18n="ErrorStatus | Opcion Error filtro estado en pagos"
              >
                Error
              </option>
            </select>
          </div>
        </div>
        <div class="col-sm">
          <div class="filtro">
            <label
              class="descCampo"
              i18n="CreationDateFilters | Filtro FechasCreacion en Pagos"
              >Fecha Creacion</label
            >
            <div class="input-group">
              <input
                class="form-control"
                placeholder="From"
                i18n-placeholder="PlaceholderFromDatePayments"
                [(ngModel)]="fechaInicio"
                ngbDatepicker
                #picker="ngbDatepicker"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary bi bi-calendar3"
                  (click)="picker.toggle()"
                  type="button"
                ></button>
              </div>

              <input
                class="form-control"
                placeholder="To"
                i18n-placeholder="PlaceholderToDatePayments"
                [(ngModel)]="fechaFin"
                ngbDatepicker
                #picker1="ngbDatepicker"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary bi bi-calendar3"
                  (click)="picker1.toggle()"
                  type="button"
                ></button>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm">
          <div class="botonBusqueda">
            <div class="mat-card-content">
              <button
                class="btn btn-flat btn-accent btn-buscar"
                (click)="buscar()"
                i18n="SearchButtonPayments | Boton Buscar en Pagos"
              >
                Buscar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- begin Payments Table -->
    <table class="table table-striped" [ngClass]="'mat-table--numbers'">
      <thead>
        <tr>
          <th
            scope="col"
            mat-sort-header
            i18n="NroPaymentPayments | NroPago columna en pagos"
          >
            N° Pago
          </th>
          <th
            scope="col"
            mat-sort-header
            i18n="ReferencePayments | Referencia columna en pagos"
          >
            Referencia
          </th>
          <th
            scope="col"
            mat-sort-header
            i18n="CreationDatePayments | FechaCreacion columna en pagos"
          >
            Fecha Creacion
          </th>
          <th
            scope="col"
            mat-sort-header
            i18n="PaymentDatePayments | FechaPago columna en pagos"
          >
            Fecha Pago
          </th>
          <th
            scope="col"
            mat-sort-header
            i18n="StatusPayments | Estado columna en pagos"
          >
            Estado
          </th>
          <th
            scope="col"
            mat-sort-header
            i18n="ValuePayments | Valor columna en pagos"
          >
            Valor
          </th>
          <th
            scope="col"
            mat-sort-header
            i18n="GatewayPayments | Pasarela columna en pagos"
          >
            Pasarela
          </th>
          <th
            scope="col"
            mat-sort-header
            i18n="AuthorizationPayments | Pasarela columna en pagos"
          >
            Autorizacion
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let element of data">
          <td>{{ element.id }}</td>
          <td>
            <span
              *ngIf="element.tipoReferencia=='ORDEN'"
              i18n="Order | TipoPagoOrden Payments"
              >Orden</span
            >
            <span
              *ngIf="element.tipoReferencia=='FACTURA'"
              i18n="Invoice | TipoPagoFactura Payments"
              >Factura</span
            >
            {{ (element.tipoReferencia=='ORDEN' ? element.idExternoOrden :
            element.id) }}
          </td>
          <td>{{ element.fechaCreado | date }}</td>
          <td>{{ element.fechaPagado | date }}</td>
          <td>
            <span
              *ngIf="element.estadoPago=='PENDIENTE'"
              i18n="Pending | EstadoPagoPendiente Payments"
              >Pendiente</span
            >
            <span
              *ngIf="element.estadoPago=='EN_PROCESO'"
              i18n="In_Process | EstadoPagoEnProceso Payments"
              >En Proceso</span
            >
            <span
              *ngIf="element.estadoPago=='PAGADO'"
              i18n="Payed | EstadoPagoPagado Payments"
              >Pagado</span
            >
            <span
              *ngIf="element.estadoPago=='NO_AUTORIZADO'"
              i18n="No_Authorized | EstadoPagoNoAutorizado Payments"
              >No Autorizado</span
            >
            <span
              *ngIf="element.estadoPago=='ERROR'"
              i18n="Error | EstadoPagoError Payments"
              >Error</span
            >
          </td>
          <td class="mat-cell--value">
            {{ element.importe }} {{element.moneda.codigo}}
          </td>
          <td>{{ element.pasarelaPago.nombre }}</td>
          <td>{{ element.codigoAutorizacion }}</td>
        </tr>
      </tbody>
    </table>
    <ngb-pagination
      [collectionSize]="total"
      [(page)]="pageIndex"
      [pageSize]="pageSize"
      [maxSize]="10"
      (pageChange)="paginar($event)"
    >
    </ngb-pagination>
  </div>
</main>
