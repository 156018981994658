import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { KeycloakService } from 'keycloak-angular';
import { Elemento } from 'src/app/shared/models/elemento.model';
import { ElementoFiltro } from 'src/app/shared/models/elementoFiltro.model';
import { ElementService } from 'src/app/shared/services/element.service';
import { environment } from 'src/environments/environment';
import { Swiper } from 'swiper/types';
import { SwiperOptions } from 'swiper/types/swiper-options';

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.scss'],
})
export class NewsDetailComponent {
  idElemento!: string;
  elemento!: Elemento;
  img_base = environment.img_base;

  constructor(
    private elementService: ElementService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.idElemento = this.router.url.substring(
        this.router.url.lastIndexOf('/') + 1,
        this.router.url.length
      );

      var filtroDetalle: ElementoFiltro = new ElementoFiltro();
      filtroDetalle.id = Number.parseInt(this.idElemento);
      this.elementService.getAll(0, 0, filtroDetalle, '').subscribe((data) => {
        this.elemento = (data as any)['elementos'][0];
      });
    });
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
