<div class="module module--bg">
  <div class="container">
    <div class="row">
      <div class="headline">
        <h4>
          Fibras es una Asociación civil sin fines de lucro, cuyo objeto es
          impulsar proyectos sustentables que -utilizando la tecnología- generen
          un impacto positivo social y ambiental en la comunidad.
        </h4>
      </div>
    </div>
  </div>
</div>

<div class="container">
  <div class="row mb-3 mt-5 justify-content-center">
    <div class="col-12">
      <h2>
        Las formas de unirte a Fibras tanto para personas, empresas u
        organizaciones son las siguientes:
      </h2>
      <article class="card first-card">
        <h3 class="card-title">SUSCRIPTOR</h3>
        <main>
          <div>
            <div>
              <h5>Inversión:</h5>

              <p>Personas</p>
              <ul>
                <h4>USD 9<span>/mes</span></h4>
              </ul>

              <p>Empresas</p>
              <ul>
                <li *ngFor="let empresa of empresas">
                  <h4>
                    {{ empresa.tipoEmpresa }} USD {{ empresa.precio
                    }}<span>/mes</span>
                  </h4>
                  <i
                    class="bi bi-question-circle-fill"
                    [ngbPopover]="empresa.infoAdicional"
                    triggers="{{ getPopoverTriggers() }}"
                  ></i>
                </li>
              </ul>
            </div>
            <ul class="rights">
              <h5>Derechos:</h5>
              <li>
                <p>Presentación de Propuestas</p>
                <i class="fa-solid fa-circle-check"></i>
              </li>
              <li>
                <p>Comisiones, Proyectos e Iniciativas</p>
                <i class="fa-solid fa-circle-check"></i>
              </li>
              <li>
                <p>Colaboración de Compromisos</p>
                <i class="fa-solid fa-circle-check"></i>
              </li>
              <li>
                <p>Sumar Comunidades y Miembros</p>
                <i class="fa-solid fa-circle-check"></i>
              </li>
            </ul>
          </div>
          <a
            class="btn btn-primary"
            *ngIf="!esSuscriptor"
            href="/marketplace/es/product/suscription/purchase/suscriptor"
            >UNIRSE SUSCRIPTOR</a
          >
        </main>
      </article>
      <article class="card secound-card">
        <h3 class="card-title">COMUNIDADES</h3>
        <main>
          <div>
            <div>
              <h5>Inversión:</h5>
              <p>Comunidades</p>
              <ul>
                <li *ngFor="let Comunidad of comunidades">
                  <h4>
                    {{ Comunidad.tipoComunidad }} USD {{ Comunidad.precio
                    }}<span>/año</span>
                  </h4>
                  <i
                    class="bi bi-question-circle-fill"
                    [ngbPopover]="Comunidad.infoAdicional"
                    triggers="{{ getPopoverTriggers() }}"
                  ></i>
                </li>
              </ul>
              <h5>
                <strong
                  >Pack Adicional 50 miembros USD 800<span>/año</span></strong
                >
              </h5>
              <br />
            </div>

            <ul class="rights">
              <h5>Condiciones:</h5>
              <li class="condition">
                Una Comunidad debe ser propuesta por un Socio Activo, Suscriptor
                o Aliado en la plataforma Fibras
              </li>
              <!-- <li class="condition">Un miembro puede adherir y colaborar en compromisos de Comunidades</li>
                            <li class="condition">Socios Activos, Suscriptores y Aliados pueden colaborar en Compromisos de todas las
                                comunidades</li> -->
            </ul>
          </div>
          <a
            class="btn btn-primary"
            *ngIf="!esSuscriptor"
            href="/marketplace/es/product/suscription/purchase/activo"
            >SUMAR COMUNIDAD</a
          >
        </main>
      </article>
      <article class="card third-card">
        <h3 class="card-title">SOCIO ACTIVO</h3>
        <main>
          <div>
            <div>
              <h5>Inversión:</h5>

              <p>Personas y Empresas</p>
              <ul>
                <li>
                  <h4>100 horas <span>/año</span></h4>
                </li>
              </ul>
            </div>

            <ul class="rights">
              <h5>Derechos:</h5>
              <li>
                <p>Gobernanza de la Comunidad</p>
                <i class="fa-solid fa-circle-check"></i>
              </li>
              <li>
                <p>Presentación de Propuestas y Voto</p>
                <i class="fa-solid fa-circle-check"></i>
              </li>
              <li>
                <p>Comisiones, Proyectos e Iniciativas</p>
                <i class="fa-solid fa-circle-check"></i>
              </li>
              <li>
                <p>Colaboración y Validación de Compromisos</p>
                <i class="fa-solid fa-circle-check"></i>
              </li>
              <li>
                <p>Sumar Comunidades y Miembros</p>
                <i class="fa-solid fa-circle-check"></i>
              </li>
            </ul>
          </div>
          <a
            class="btn btn-primary"
            *ngIf="!esSuscriptor"
            href="/marketplace/es/product/suscription/purchase/activo"
            >UNIRSE ACTIVO</a
          >
        </main>
      </article>
      <article class="card fourth-card">
        <h3 class="card-title">ALIADO</h3>
        <main>
          <div>
            <div>
              <h5>Inversión:</h5>
              <p>Alianza</p>
            </div>

            <ul class="rights">
              <ul>
                <li>
                  <h4>Sin Costo</h4>
                  <br />
                  <br />
                  <br />
                </li>
              </ul>
              <h5>Derechos:</h5>
              <li>
                <p>Presentación de Propuestas</p>
                <i class="fa-solid fa-circle-check"></i>
              </li>

              <li>
                <p>Comisiones, Proyectos e Iniciativas</p>
                <i class="fa-solid fa-circle-check"></i>
              </li>

              <li>
                <p>Colaboración de Compromisos</p>
                <i class="fa-solid fa-circle-check"></i>
              </li>

              <li>
                <p>Sumar Comunidades y Miembros</p>
                <i class="fa-solid fa-circle-check"></i>
              </li>

              <br />

              <h5>Condición:</h5>
              <li class="condition">
                Aplica a Asociaciones, ONGs y Cooperativas que compartan el
                propósito de Fibras
              </li>
            </ul>
          </div>
          <a
            class="btn btn-primary"
            *ngIf="!esSuscriptor"
            href="/marketplace/es/product/suscription/purchase/aliado"
            >UNIRSE ALIADO</a
          >
        </main>
      </article>
    </div>
  </div>
</div>
<h2 class="display-6 text-center">
  <i class="bi bi-filetype-pdf"></i>
  Descargar
  <a href="/assets/estatutos.pdf" target="_blank">“Estatutos Fibras.pdf”</a>
</h2>
