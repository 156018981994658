<div class="modal-body">
  <button
    type="button"
    class="btn-close float-end"
    aria-label="Close"
    (click)="modalService.dismiss('Cross click')"
  ></button>
  <div class="row m-0" style="width: 100%">
    <div class="col-12 col-lg-5 p-0">
      <div class="profile-wrap">
        <div class="person">
          <figure class="person__photo">
            <img
              *ngIf="elemento['listadoImage']"
              class="img-fluid"
              src="{{ img_base }}{{ elemento.listadoImage }}"
              alt="{{ elemento.nombre }}"
            />

            <img
              *ngIf="!elemento['listadoImage']"
              class="img-fluid"
              src="/assets/img/imgs/default.jpg"
              alt="{{ elemento.nombre }}"
            />
          </figure>
          <div class="person__name__date">
            <span class="person__name">{{ elemento.nombre }}</span>
            <span *ngIf="elemento.fechaInfoInicio" class="person__date"
              >( {{ elemento.fechaInfoInicio | date : "dd/MM/yyyy " }} )</span
            >
          </div>
          <span class="person__rol">{{ elemento.copete }}</span>
          <span
            style="cursor: pointer; text-decoration: underline;"
            *ngIf="elemento.empresa_nombre"
            class="person__rol"
            (click)="this.openModal(elemento.empresa_id)"
            ><strong>{{ elemento.empresa_nombre }}</strong></span
          >
          <span
            style="cursor: pointer; text-decoration: underline;"
            *ngIf="elemento.responsable_nombre"
            class="person__rol"
            (click)="this.openModal(elemento.responsable_id)"
            ><strong>Responsable: {{ elemento.responsable_nombre }}</strong></span
          >
          <div class="footer m-0">
            <!-- ToDo: INés_20231209: reemplazar para liberar campos: uncomment l32,33
                                campo fuente cambia a 'email' de multimedia
                                campo infoAdicional cambia a 'telefono' de multimedia-->
            <div class="details mb-3 m-0">
              <span *ngIf="elemento.link"
                ><i class="bi bi bi-globe"></i>&nbsp;<a
                  href="{{ elemento.link }}"
                  class="fw-bold link"
                  target="_blank"
                  >{{ elemento.link }}</a
                ></span
              >
              <span *ngIf="elemento.fuente"
                ><i class="bi bi-envelope-fill"></i> {{ elemento.fuente }}</span
              >
              <span *ngIf="elemento.infoAdicional" class="phone"
                ><i class="bi bi-phone px-1"></i>
                <span innerHtml="{{ elemento.infoAdicional }}"></span>
              </span>
              <span *ngIf="elemento['linkedin']">
                <a
                  href="{{ elemento['linkedin'] }}"
                  target="_blank"
                  class="linkedin"
                  ><i class="bi bi-linkedin"></i
                ></a>
              </span>
              <!-- <span *ngIf="elemento.email"><i class="bi bi-envelope-fill"></i> {{elemento.email}}</span> -->
              <!-- <span *ngIf="elemento.telefono"><i class="bi bi-phone"></i> {{elemento.telefono}}</span> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-7 p-0">
      <div class="contact-wrap">
        <div class="contents" *ngIf="elemento.descripcion">
          <div class="title">Experiencia/expertise:</div>
          <div class="card-body">
            <span [innerHTML]="elemento.descripcion"></span>
          </div>
        </div>

        <div
          class="contents"
          *ngIf="elemento.categorias | categoriaFilter : 'dimension'"
        >
          <div class="title">Dimensiones en las que le gustaría colaborar:</div>
          <div class="card-body">
            <ul>
              <ng-container *ngFor="let cat of elemento.categorias">
                <div
                  *ngIf="cat.categoria.padre.tipo_clasificacion == 'dimension'"
                >
                  <li class="text-small">
                    <i class="bi bi-caret-right-fill"></i>
                    <span> {{ cat.categoria.nombre }}</span>
                  </li>
                </div>
              </ng-container>
            </ul>
          </div>
        </div>

        <div class="contents" *ngIf="elemento.descripcionHtml">
          <div class="title">
            Cómo le gustaría colaborar en los temas e iniciativas seleccionadas:
          </div>
          <div class="card-body">
            <span [innerHTML]="elemento.descripcionHtml"> </span>
          </div>
        </div>

        <div
          class="contents"
          *ngIf="elemento.categorias | categoriaFilter : 'etapa'"
        >
          <div class="title">
            Etapa en que debería estar la iniciativa para colaborar:
          </div>
          <div class="card-body">
            <ul>
              <ng-container *ngFor="let cat of elemento.categorias">
                <div *ngIf="cat.categoria.padre.tipo_clasificacion == 'etapa'">
                  <li class="text-small">
                    <i class="bi bi-check-square"></i>
                    <span> {{ cat.categoria.nombre }}</span>
                  </li>
                </div>
              </ng-container>
            </ul>
          </div>
        </div>
        <div
          class="contents"
          *ngIf="elemento.categorias | categoriaFilter : 'ods'"
        >
          <div class="title">ODS en los que le gustaría colaborar:</div>
          <div class="card-body">
            <ul>
              <ng-container *ngFor="let cat of elemento.categorias">
                <div *ngIf="cat.categoria.padre.tipo_clasificacion == 'ods'">
                  <li class="text-small">
                    <i class="bi bi-check-square"></i>
                    <a
                      target="_blank"
                      style="color: black"
                      [href]="cat.categoria.ods_url"
                      ><span>
                        {{ cat.categoria.ods_number }} -
                        {{ cat.categoria.nombre }}</span
                      >
                    </a>
                  </li>
                </div>
              </ng-container>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
