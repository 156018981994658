import { CategoriaImgFilterService } from './categoria-img-filter.service';
import { Pipe, PipeTransform } from '@angular/core';
import { CategoriaElemento } from './shared/models/categoria-elemento.model';

@Pipe({
  name: 'categoriaImgFilter',
})
export class CategoriaImgFilterPipe implements PipeTransform {
  // INés_20231201: using tipo_clasificacion + nivel padre == 0 from json
  transform(categorias: CategoriaElemento[], tipo: any): unknown {
    console.log(tipo);
    let result: string[] = [];
    if (!!categorias) {
      categorias.forEach((CategoriaElemento) => {
        if (
          tipo == CategoriaElemento.categoria.padre.tipo_clasificacion &&
          CategoriaElemento.categoria.padre.nivel === '0'
        ) {
          if (tipo == 'ods') {
            result.push(
              `<img width="90px" src="assets/img/ods/${CategoriaElemento.categoria.ods_number}.png" />`
            );
          } else {
            result.push(CategoriaElemento.categoria.nombre);
          }
        }
      });

      // Ordenar el array por 'ods_number' de menor a mayor
      result.sort((a, b) => {
        if (tipo === 'ods') {
        
          const matchA = a.match(/\d+/);
          const matchB = b.match(/\d+/);

          if (matchA && matchB) {
            const numeroA = parseInt(matchA[0], 10);
            const numeroB = parseInt(matchB[0], 10);
            return numeroA - numeroB;
          } else {
            console.error("Error al extraer 'ods_number' de los elementos.");
            return 0;
          }
        } else {
          return 0;
        }
      });

      if (result.length == 0) {
        result.push('');
      }
    }
    if (tipo == 'ods') {
      return result.join('');
    } else {
      return result.join(' / ');
    }
  }

  constructor(private categoriaImgFilterService: CategoriaImgFilterService) {}
}
