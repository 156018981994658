import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { ElementoFiltro } from 'src/app/shared/models/elementoFiltro.model';
import { CompromisoService } from 'src/app/shared/services/compromiso.service';
import { ElementService } from 'src/app/shared/services/element.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-commitment-detail',
  templateUrl: './commitment-detail.component.html',
  styleUrls: ['./commitment-detail.component.scss'],
})
export class CommitmentDetailComponent implements OnInit {
  returnUrl: string | undefined;
  constructor(
    private compromisoService: CompromisoService,
    private router: Router,
    private route: ActivatedRoute,
    private elementService: ElementService,
  ) {}
  idCompromiso!: string;
  compromiso!: any;
  members: any[] = [];
  apps: any[] = [];
  compromisoUrl = `${environment.url_sincronia_comunidad}`;
  ngOnInit(): void {
    this.idCompromiso = this.route.snapshot.paramMap.get('id') || '';
    this.compromisoUrl += this.idCompromiso;

    this.compromisoService
      .getCompromiso(this.idCompromiso)
      .subscribe(async (data: any) => {
        this.compromiso = data['commitments'][0];
        const filter = new ElementoFiltro();
        filter.modulo = 'Comunidades';
        filter.fuente = this.compromiso.community;

        const community = await lastValueFrom(
          this.elementService.getAll(0, 0, filter, ''),
        );

        this.returnUrl = `communities-commitments/${community.elementos[0].id}`;

        const resp = await this.compromisoService.getMembersPorCompromiso(
          this.compromiso.community,
          this.idCompromiso,
        );

        this.members = resp.persons
          .map((person: any) => ({
            ...person,
            statusText: this.getUserStatusInCommitment(person.commitments),
            deadlineDate: this.getFechaLimiteInCommitment(person.commitments),
          }))
          .sort((a: any, b: any) => a.firstName.localeCompare(b.firstName));

        const respApps =
          await this.compromisoService.getApplicationsPorCompromiso(
            this.idCompromiso,
          );

        this.apps = respApps.applications.map((app: any) => ({
          ...app,
          statusText: this.getUserStatusInCommitment(app.commitments),
          deadlineDate: this.getFechaLimiteInCommitment(app.commitments),
          companyName: this.compromisoService.getCompanyNameById(app.companyId),
        }));

        console.log(this.apps);
        console.log(this.members);
      });
  }

  getUserStatusInCommitment(list: any[]): string {
    const comm = list.find(
      (item: any) => Number(item.commitmentCode) === Number(this.idCompromiso),
    );
    switch (comm.status) {
      case 1:
        return '💬 Tiene intención';
      case 2:
        return '✅ Cumple';
      default:
        return 'No cumple';
    }
  }
  getFechaLimiteInCommitment(list: any[]): string {
    const comm = list.find(
      (item: any) => Number(item.commitmentCode) === Number(this.idCompromiso),
    );
    if (comm.status === 2) return '';
    return new Date(comm.deadlineDate).toLocaleDateString('es-UY');
  }
}
