import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDateStruct, NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import {
  NgbDateAdapter,
  NgbDateNativeAdapter,
} from '@ng-bootstrap/ng-bootstrap';
import { jwtDecode } from 'jwt-decode';
import { JWTContent } from 'src/app/app.component';
import { PaymentService } from 'src/app/shared/services/payment.service';
import { SuscripcionService } from 'src/app/shared/services/suscripcion.service';
import { environment } from 'src/environments/environment';

export interface PaymentsData {
  id: number;
  idReferencia: number;
  idExternoOrden: string;
  tipoReferencia: string;
  fechaPagado: Date;
  fechaCreado: Date;
  pasarelaPago: PasarelaPago;
  estadoPago: string;
  importe: number;
  importeGravado: number;
  codigoAutorizacion: string;
  moneda: Moneda;
}

export interface PasarelaPago {
  id: number;
  idExterno: string;
  nombre: string;
}

export interface Moneda {
  id: number;
  codigo: string;
  moneda: string;
  simbolo: string;
}

@Component({
  selector: 'app-payments',
  templateUrl: './payments.page.html',
  styleUrls: ['./payments.page.scss'],
  providers: [
    NgbPaginationConfig,
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    // { provide: NgbDateParserFormatter, useClass: NgbDateParserFormatter }
  ],
})
export class PaymentsPage {
  pageIndex = 1;
  pageSize = 10;
  total!: number;
  data: PaymentsData[] = [];

  displayedColumns: string[] = [
    'paymentNumber',
    'reference',
    'dateCreated',
    'datePayment',
    'status',
    'value',
    'gateway',
    'authorization',
  ];

  estado!: string;
  tipo!: string;
  rangoDias!: string;
  fechaInicio!: NgbDateStruct;
  fechaFin!: NgbDateStruct;
  hasAccess = false;

  @ViewChild('paginator') paginator: any; // Replace 'MatPaginator' with 'any'
  @ViewChild('sort') sort: any; // Replace 'MatSort' with 'any'

  constructor(
    public paymentService: PaymentService,
    private paginationConfig: NgbPaginationConfig,
  ) {
    paginationConfig.boundaryLinks = true; // Configure pagination options
    paginationConfig.pageSize = this.pageSize;
  }

  async ngOnInit(): Promise<void> {}

  buscar() {
    this.paymentService
      .getAll(
        this.pageSize,
        (this.pageIndex - 1) * this.pageSize,
        this.fechaInicio,
        this.fechaFin,
        this.rangoDias,
        this.estado,
        this.tipo,
      )
      .subscribe(resp => {
        this.data = resp.pagos;
        this.total = resp.total;
      });
  }

  paginar(paginator: any) {
    this.pageIndex = paginator;
    this.buscar();
  }
}
