<section>
  <!-- begin Module -->
  <div class="module module--bg">
    <a class="volver" routerLink="/projects">
      <svg
        width="14"
        height="8"
        viewBox="0 0 16 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.8125 1.90625L8 8.09375L14.1875 1.90625"
          stroke="#005a5a"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        /></svg
      ><span>Volver a Proyectos</span>
    </a>
    <div class="container">
      <div class="row">
        <div class="col-lg-8 offset-lg-2 text-center headline">
          <h1 class="typography__headline">Postulá tu proyecto</h1>
          <p>
            Si tienes un proyecto con impacto ambiental y social que desees
            potenciar con tecnología, puedes postularlo a Fibras para recibir
            apoyo de nuestra organización, sumando horas de trabajo de nuestros
            socios en las siguientes áreas:
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- Detalle debajo del nombre - Waves -->
  <svg
    id="wave"
    style="transform: rotate(180deg); transition: 0.3s"
    viewBox="0 0 1440 120"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
        <stop stop-color="rgba(245.552, 255, 254.73, 1)" offset="0%"></stop>
        <stop stop-color="rgba(205, 240, 239, 1)" offset="100%"></stop>
      </linearGradient>
    </defs>
    <path
      style="transform: translate(0, 0px); opacity: 1"
      fill="url(#sw-gradient-0)"
      d="M0,96L60,96C120,96,240,96,360,90C480,84,600,72,720,72C840,72,960,84,1080,90C1200,96,1320,96,1440,90C1560,84,1680,72,1800,64C1920,56,2040,52,2160,46C2280,40,2400,32,2520,34C2640,36,2760,48,2880,52C3000,56,3120,52,3240,56C3360,60,3480,72,3600,72C3720,72,3840,60,3960,48C4080,36,4200,24,4320,28C4440,32,4560,52,4680,56C4800,60,4920,48,5040,44C5160,40,5280,44,5400,46C5520,48,5640,48,5760,42C5880,36,6000,24,6120,22C6240,20,6360,28,6480,36C6600,44,6720,52,6840,48C6960,44,7080,28,7200,18C7320,8,7440,4,7560,14C7680,24,7800,48,7920,56C8040,64,8160,56,8280,46C8400,36,8520,24,8580,18L8640,12L8640,120L8580,120C8520,120,8400,120,8280,120C8160,120,8040,120,7920,120C7800,120,7680,120,7560,120C7440,120,7320,120,7200,120C7080,120,6960,120,6840,120C6720,120,6600,120,6480,120C6360,120,6240,120,6120,120C6000,120,5880,120,5760,120C5640,120,5520,120,5400,120C5280,120,5160,120,5040,120C4920,120,4800,120,4680,120C4560,120,4440,120,4320,120C4200,120,4080,120,3960,120C3840,120,3720,120,3600,120C3480,120,3360,120,3240,120C3120,120,3000,120,2880,120C2760,120,2640,120,2520,120C2400,120,2280,120,2160,120C2040,120,1920,120,1800,120C1680,120,1560,120,1440,120C1320,120,1200,120,1080,120C960,120,840,120,720,120C600,120,480,120,360,120C240,120,120,120,60,120L0,120Z"
    ></path>
    <defs>
      <linearGradient id="sw-gradient-1" x1="0" x2="0" y1="1" y2="0">
        <stop stop-color="rgba(205, 240, 239, 1)" offset="0%"></stop>
        <stop stop-color="rgba(205, 240, 239, 0)" offset="100%"></stop>
      </linearGradient>
    </defs>
    <path
      style="transform: translate(0, 50px); opacity: 0.9"
      fill="url(#sw-gradient-1)"
      d="M0,48L60,54C120,60,240,72,360,64C480,56,600,28,720,24C840,20,960,40,1080,50C1200,60,1320,60,1440,56C1560,52,1680,44,1800,34C1920,24,2040,12,2160,24C2280,36,2400,72,2520,84C2640,96,2760,84,2880,76C3000,68,3120,64,3240,60C3360,56,3480,52,3600,52C3720,52,3840,56,3960,52C4080,48,4200,36,4320,26C4440,16,4560,8,4680,16C4800,24,4920,48,5040,56C5160,64,5280,56,5400,62C5520,68,5640,88,5760,92C5880,96,6000,84,6120,72C6240,60,6360,48,6480,46C6600,44,6720,52,6840,46C6960,40,7080,20,7200,18C7320,16,7440,32,7560,50C7680,68,7800,88,7920,94C8040,100,8160,92,8280,92C8400,92,8520,100,8580,104L8640,108L8640,120L8580,120C8520,120,8400,120,8280,120C8160,120,8040,120,7920,120C7800,120,7680,120,7560,120C7440,120,7320,120,7200,120C7080,120,6960,120,6840,120C6720,120,6600,120,6480,120C6360,120,6240,120,6120,120C6000,120,5880,120,5760,120C5640,120,5520,120,5400,120C5280,120,5160,120,5040,120C4920,120,4800,120,4680,120C4560,120,4440,120,4320,120C4200,120,4080,120,3960,120C3840,120,3720,120,3600,120C3480,120,3360,120,3240,120C3120,120,3000,120,2880,120C2760,120,2640,120,2520,120C2400,120,2280,120,2160,120C2040,120,1920,120,1800,120C1680,120,1560,120,1440,120C1320,120,1200,120,1080,120C960,120,840,120,720,120C600,120,480,120,360,120C240,120,120,120,60,120L0,120Z"
    ></path>
  </svg>
  <!-- Fin de Waves -->
  <!-- end Module -->

  <!-- begin Module -->
  <div>
    <!-- begin Module -->
    <div class="container">
      <div class="card-list">
        <div class="">
          <h4>Mentorías</h4>
          Reuniones individuales con el equipo del emprendimiento o iniciativa.
          Se comparten conocimientos y experiencias con el fin de avanzar en su
          desarrollo.
        </div>
        <div>
          <h4>Asesorías</h4>
          Reuniones individuales donde se profundiza en los problemas y desafíos
          detectados del emprendimiento o iniciativa y se brindan
          recomendaciones y sugerencias de cómo resolverlos. Algunos ejemplos de
          especialidades y apoyos que brindamos son:
          <ul>
            <li>
              &rarr; Apoyo en la formulación de proyectos para la obtención de
              financiamiento.
            </li>
            <li>&rarr; Medición del impacto.</li>
            <li>&rarr; Diseño de la estrategia de sostenibilidad.</li>
            <li>&rarr; Plan de mejora del desempeño social y ambiental.</li>
            <li>&rarr; Apoyo legal y contable para emprendimientos.</li>
            <li>
              &rarr; Coaching, counseling y acompañamiento humano a
              emprendedores.
            </li>
            <li>&rarr; Comunicación y marketing.</li>
          </ul>
        </div>
        <div>
          <h4>Formación</h4>
          Cursos y talleres dictados sobre un tema en particular. Pueden ser
          presenciales o virtuales, en vivo o grabados. Algunos ejemplos son:

          <ul>
            <li>&rarr; Desarrollo de un plan de negocio</li>
            <li>&rarr; ¿Cómo incorporar tecnología a mi emprendimiento?</li>
            <li>
              &rarr; Herramientas clave para la comercialización de productos
            </li>
          </ul>
        </div>
        <div>
          <h4>Comunidad</h4>
          Formarás parte de Fibras, una comunidad de más de 100 personas y
          empresas con distintos perfiles profesionales que permitan obtener una
          devolución sobre el proyecto y recomendaciones para seguir avanzando
        </div>
        <div>
          <h4>Plataforma</h4>
          Integrarás tu comunidad a la plataforma de Fibras, en la que tus
          miembros podrán participar en iniciativas y compromisos comunitarios.
        </div>
      </div>
      <div class="requisitos">
        <h4>Requisitos para que un proyecto sea elegible:</h4>
        <div class="divider mb-2"></div>
        <p class="mb-0">
          Los proyectos deberán tener un impacto positivo a nivel social o
          ambiental e incluir, de alguna forma, el uso de la tecnología en su
          propuesta de valor.
        </p>
      </div>
      <a
        class="postulate-btn"
        href="https://forms.gle/wZreP1wTiRDRL8tq6"
        target="_blank"
        >Postula tu proyecto aquí</a
      >
    </div>
  </div>
</section>
