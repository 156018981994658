import { Component, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { KeycloakService } from 'keycloak-angular';
import { PartnerDialogComponent } from 'src/app/modules/aboutus/component/partner-dialog/partner-dialog.component';
import { Elemento } from 'src/app/shared/models/elemento.model';
import { ElementoFiltro } from 'src/app/shared/models/elementoFiltro.model';
import { ElementService } from 'src/app/shared/services/element.service';
import { environment } from 'src/environments/environment';
import { ParticipanteDetailComponent } from './details/participante-detail.component';
import { Categoria } from 'src/app/shared/models/categoria.model';
import { CategoriasService } from 'src/app/shared/services/categorias.service';
import {
  CommunityDataAbstractService,
  communityDataToken,
} from '../../../community-data';
import { CommunityPanelService } from '../../../community-panel.service';

@Component({
  selector: 'app-participantes',
  templateUrl: './participantes.component.html',
  styleUrls: ['./participantes.component.scss'],
})
export class CommunityPanelParticipantesComponent {
  modalService = inject(NgbModal);
  communityService = inject<CommunityPanelService>(communityDataToken);
  infoParticipantes = this.communityService.getInfo('participantes');
  img_base = environment.img_base;

  pageIndex = 0;
  desplazamiento = 0;
  limite = 36;
  totalItems = 0;

  elementos$: Elemento[] = [];
  isMobileWidth = false;

  odsList: Categoria[] = [];
  etapaList: Categoria[] = [];
  dimensionList: Categoria[] = [];
  isFirstLoad = true;

  public filtro = new FormGroup({
    nombre: new FormControl(),
    dimension: new FormControl(),
    copete: new FormControl(),
  });

  ngOnInit() {
    if (window.screen.width < 1024) {
      // 768px portrait
      this.isMobileWidth = true;
    }

    this.filtro.patchValue({
      nombre: '',
      dimension: '0',
      copete: '',
    });

    this.filtrar(false);
  }

  public async filtrar(cargarMas: boolean): Promise<void> {
    this.pageIndex++;
    this.desplazamiento = this.pageIndex * this.limite;
    if (!cargarMas) {
      this.pageIndex = 0;
      this.desplazamiento = 0;
    }
    var filtroParticipantes: ElementoFiltro = new ElementoFiltro();
    filtroParticipantes.nombre = this.filtro.controls['nombre'].value;
    filtroParticipantes.copete = this.filtro.controls['copete'].value;

    const data = await this.communityService.getParticipantes(
      this.limite,
      this.pageIndex,
      filtroParticipantes,
    );

    this.elementos$ = cargarMas
      ? [...this.elementos$, ...data.elementos]
      : data.elementos;
    this.elementos$.sort((a, b) => {
      if (a.nombre.toLowerCase().trim() < b.nombre.toLowerCase().trim())
        return -1;
      if (a.nombre.toLowerCase().trim() > b.nombre.toLowerCase().trim())
        return 1;
      return 0;
    });
    if (!this.isFirstLoad) {
      const elementToScroll = document.querySelector('.filters-section');
      elementToScroll?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
    this.totalItems = data.total;
  }

  public resetear(): void {
    this.filtro.reset({
      nombre: '',
      dimension: '0',
      copete: '',
    });
    this.filtrar(false);
  }

  public loadMore() {
    this.desplazamiento = this.pageIndex * this.limite;
    this.filtrar(true);
  }

  openModal(id: number) {
    const modalRef = this.modalService.open(ParticipanteDetailComponent, {
      centered: true,
      modalDialogClass: 'person--dialog',
    });
    modalRef.componentInstance.id = id;
  }
}
