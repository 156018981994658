import { CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { KeycloakService } from 'keycloak-angular';
import { ModalDialogComponent } from 'src/app/shared/components/modal-dialog/modal-dialog.component';
import { Elemento } from 'src/app/shared/models/elemento.model';
import { ElementoFiltro } from 'src/app/shared/models/elementoFiltro.model';
import { ElementService } from 'src/app/shared/services/element.service';
import { SuscripcionService } from 'src/app/shared/services/suscripcion.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  esSuscriptor: boolean = false;
  tieneSuscripcionesPendientes: Boolean = false;
  token: string = '';
  constructor(
    private elementService: ElementService,
    private suscripcionService: SuscripcionService,
    private keycloakService: KeycloakService,
    private router: Router,
    private modalService: NgbModal,
  ) {}

  ngOnInit() {
    this.suscripcionService.isSuscriptor().subscribe((result) => {
      this.esSuscriptor = result;
    });

    this.suscripcionService.hasPendingSuscription().subscribe((result) => {
      this.tieneSuscripcionesPendientes = result;
    });

    this.keycloakService.getToken().then((token) => (this.token = token));
  }

  buttonClick() {
    if (this.esSuscriptor) {
      window.open(
        `${environment.url_sincronia}user_id=${this.suscripcionService.idClienteConfirmado}&token=${this.token}`,
        '_blank',
      );
      return;
    }
    if (this.tieneSuscripcionesPendientes) {
      this.openModal(
        'Usted ya tiene una suscripcion hecha en estado pendiente de verificacion. Pronto le enviaremos novedades de la misma y como proseguir.',
      );
      return;
    }
    this.router.navigate(['/market']);
  }

  openModal(texto: string) {
    const modalRef = this.modalService.open(ModalDialogComponent, {
      centered: true,
      size: 'lg',
      modalDialogClass: 'person--dialog',
    });
    modalRef.componentInstance.texto = texto;
  }
}
