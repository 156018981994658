import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ContactFormService {

  constructor(private httpClient: HttpClient) {

  }

  public send(params: any) {
    return this.httpClient.post(environment.api_tienda + 'tiendas/' + environment.idStore + '/contacto', params);
    // return this.httpClient.post(`https://www.fibras.org/tiendas/525/contacto`, params);
    //return this.httpClient.post('https://webhook.site/8b98c4fa-c3e0-44f4-b99f-1c02a55fd5b5', params);
  }
}
