import { Component } from '@angular/core';
import { register } from 'swiper/element/bundle';

@Component({
  selector: 'app-carrousel-two',
  templateUrl: './carrousel-two.component.html',
  styleUrls: ['./carrousel-two.component.scss']
})
export class CarrouselTwoComponent {

}
