<div class="px-4 py-5 my-5 text-center">
    <h1 class="display-5 fw-bold text-body-emphasis">Muchas gracias por tu interés.</h1>
    <div class="col-lg-6 mx-auto">
        <p class="lead mb-4">El equipo de Fibras se encuentra en proceso de validación de tu solicitud y en breve nos
            pondremos en contacto contigo.</p>

        <p class="lead mb-4">Por favor, ten en cuenta que nuestro tiempo de respuesta puede variar, pero generalmente,
            nos comunicaremos
            contigo en un plazo de 48 a 72 horas.</p>

        <p class="lead mb-4">Muchas gracias.</p>
        
        </div>
</div>