<section>
  <swiper-container navigation="{
    nextEl: '.button-next',
    prevEl: '.button-prev'
  }" pagination="false" slides-per-view="1">

    <swiper-slide class="elementCarousel" *ngFor="let elemento of elementos$">
      <h2 id="text" innerHTML="{{elemento.descripcion}}"></h2><img class="imagen" src="{{img_base}}{{elemento['linkImagen']}}">
    </swiper-slide>

  </swiper-container>
</section>