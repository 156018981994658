import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { KeycloakService } from 'keycloak-angular';
import { CompromisoType } from 'src/app/modules/communities/types';
import { ModalDialogComponent } from 'src/app/shared/components/modal-dialog/modal-dialog.component';
import { ElementService } from 'src/app/shared/services/element.service';
import { SuscripcionService } from 'src/app/shared/services/suscripcion.service';
import { environment } from 'src/environments/environment';
import {
  CommunityDataAbstractService,
  communityDataToken,
  DataComprimisosType,
} from '../../../community-data';
import { Compromiso } from 'src/app/shared/models/compromiso.model';

@Component({
  selector: 'app-compromisos',
  templateUrl: './compromisos.component.html',
  styleUrls: ['./compromisos.component.scss'],
})
export class CommunityPanelCompromisosComponent implements OnInit {
  compromisosList: Compromiso[] = [];
  communityService = inject<CommunityDataAbstractService>(communityDataToken);

  infoCompromisos = this.communityService.getInfo(
    'compromisos',
  ) as DataComprimisosType;

  ngOnInit(): void {
    this.getCompromisos();
  }

  async getCompromisos() {
    const data = await this.communityService.getCompromisos();
    this.compromisosList = data.commitments;
  }
}
