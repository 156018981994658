import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal-dialog',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.component.scss'],
})
export class ModalDialogComponent {

  @Input()
  texto!: string;
  @Input() className!: 'alert' | 'normal' | 'warning' | 'success'

  img_base = environment.img_base;

  constructor(
    public modalService: NgbActiveModal
  ) {}

  ngOnInit(): void {

  }

  onCloseClick(): void {
    this.modalService.close();
  }
}
