<div class="container">
    <img src="/assets/img/imgs/universo.png">
    <swiper-container class="carousel" navigation="{
    nextEl: '.button-next',
    prevEl: '.button-prev'
  }" pagination="false" slides-per-view="1">
        <swiper-slide class="elementCarousel">
            <div class="content">
                <p>"Agradezco al universo que nos haya puesto en el camino para cocrear Fibras junto a personas
                    increíbles, con gran talento, experiencia pero mucho más empatía y vocación de servicio.
                    Sincronicidad, Sincronía y Satisfacción están en la "S" de FibraS"</p>
                <span class="testimonial-name">Juan Ignacio Machado</span>
                <span class="title">Socio Fundador Fibras</span>
            </div>
        </swiper-slide>
        <swiper-slide class="elementCarousel">
            <div class="content">
                <p>"Motivado en amar al prójimo, tanto como a mí mismo, reconocí en Fibras y en su gente, de
                    inmediato, la tecnología perfecta, para alinear los proyectos y acciones, que conduzcan
                    desde la propia experiencia del Ser Humano, al encuentro en bienestar consigo mismo."</p>
                <span class="testimonial-name">Juan Pablo Diaz Magariños</span>
                <span class="title">Socio Fundador Fibras</span>
            </div>
        </swiper-slide>
        <swiper-slide class="elementCarousel">
            <div class="content">
                <p>"En el espacio de Fibras siento la alineación total entre el sentir, el pensar, el decir y el
                    hacer. Ser parte de este proyecto me llena de energía."</p>
                <span class="testimonial-name">Dinorah Margounato</span>
                <span class="title">Socia Fundadora</span>
            </div>
        </swiper-slide>
        <swiper-slide class="elementCarousel">
            <div class="content">
                <p>"Fibras es un colectivo de personas y empresas que tienen sueños similares. Enfocados en
                    contribuir para hacer, entre todos, un mundo más solidario, humano, equitativo y
                    sustentable. Un
                    propósito que agrega valor a la vida de todos. <br>
                    Orgulloso de pertenecer 🙂"</p>
                <span class="testimonial-name">Luis Benia</span>
                <span class="title">Socio Fundador</span>
            </div>
        </swiper-slide>
        <swiper-slide class="elementCarousel">
            <div class="content">
                <p>"Creemos en un mundo humanista, ético y solidario y sentimos que es posible conectar a
                    nuestra
                    sociedad y a nuestro planeta con todo su potencial de bienestar y realización. Para esto nos
                    propusimos hacer, poniendo mucho amor y todo nuestro humilde saber y experiencia, al
                    servicio de este propósito."</p>
                <span class="testimonial-name">Diego Sastre</span>
                <span class="title">Socio Fundador</span>
            </div>
        </swiper-slide>
    </swiper-container>
</div>