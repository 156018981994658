import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  concatMap,
  from,
  lastValueFrom,
  mergeMap,
  of,
  switchMap,
} from 'rxjs';
import { Suscripcion, SuscripcionEstado } from '../models/suscripcion.model';
import { environment } from 'src/environments/environment';
import { KeycloakService } from 'keycloak-angular';
import { jwtDecode } from 'jwt-decode';
import { JWTContent } from 'src/app/app.component';

@Injectable({
  providedIn: 'root',
})
export class SuscripcionService {
  public suscripciones: any[] | undefined = [];
  public idClienteConfirmado: number | null = null;
  public isSuscriptor$ = new BehaviorSubject(false);
  public hasPendingSuscription$ = new BehaviorSubject(false);
  public memberId: number | undefined = undefined;

  constructor(
    private httpClient: HttpClient,
    private keycloakService: KeycloakService,
  ) {
    this.initialize();
  }

  private async initialize() {

    try {
      const isLoggedIn = await this.keycloakService.isLoggedIn();
      const token = await this.keycloakService.getToken();
      const { memberId } = jwtDecode(token) as JWTContent;
      this.memberId = memberId;

      if (!isLoggedIn) {
        this.isSuscriptor$.next(false);
        this.hasPendingSuscription$.next(false);
        return;
      }

      //TIENE MEMBER ID > SUSCRIPCION ACTIVA
      if (this.memberId && Number(this.memberId) > 0) {
        console.log('memberId: ', this.memberId);
        this.isSuscriptor$.next(true);
        this.hasPendingSuscription$.next(false);
        return;
      }

      const suscripciones = await this.obtenerSuscripciones();
      this.suscripciones = suscripciones;

      //NO TIENE MEMBER ID NI SUSCRIPCIONES > NO TIENE SUSCRIPCION
      if (!this.suscripciones || !this.suscripciones.length) {
        this.isSuscriptor$.next(false);
        this.hasPendingSuscription$.next(false);
        return;
      }

      if (this.suscripciones.length) {
        const pendingSus = this.suscripciones.find(
          sus => sus.estado.nombre === SuscripcionEstado.PENDIENTE,
        );

        // TIENE UNA SUSCRIPCION PENDIENTE > INICIO EL PROCESO DE SUSCRIPCION
        if (pendingSus) {
          this.isSuscriptor$.next(false);
          this.hasPendingSuscription$.next(true);
          return;
        }

        //LA UNICA OPCION QUE QUEDA ES QUE TENGA UNA O MAS SUSCRIPCIONES CANCELADAS > NO TIENE SUSCRIPCION
        this.isSuscriptor$.next(false);
        this.hasPendingSuscription$.next(false);
      }
    } catch (error) {
      console.error('Error during initialization:', error);
    }
  }

  public obtenerSuscripciones(): Promise<Suscripcion[]> {
    let params = new HttpParams();
    params = params.append('lang', 'es');

    return lastValueFrom(
      this.httpClient.get<Suscripcion[]>(
        environment.api_tienda +
          'tiendas/' +
          environment.idStore +
          '/contratos/usuario',
        { params },
      ),
    );
  }

  public isSuscriptor(): Observable<boolean> {
    return this.isSuscriptor$.asObservable();
  }

  public isSuscriptorSync(): boolean {
    return this.isSuscriptor$.getValue();
  }

  public hasPendingSuscription(): Observable<boolean> {
    return this.hasPendingSuscription$.asObservable();
  }

  //METODO SOLO PARA CAMBIAR Y PROBAR MODOS - QUITAR LUEGO
  public becameSuscriptor(status: boolean) {
    this.isSuscriptor$.next(status);
  }

  parseJwt(token: string): any {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(char => {
          return '%' + ('00' + char.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(jsonPayload);
  }
}
