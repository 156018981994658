<div class="background">
  <div class="container">
    <div class="row">
      <div class="col-12 mt-4 mb-4">
        <h1
          i18n="UserDetails | Datos de usuario"
          class="mat-typography__underlined"
        >
          Datos de Usuario
        </h1>
      </div>
    </div>
    <form [formGroup]="userForm" class="form">
      <div class="row mb-3">
        <div class="col-sm">
          <form-group appearance="fill">
            <label class="descCampo" for="nombre">Email </label>
            <input
              type="text"
              id="email"
              formControlName="email"
              class="form-control"
            />
          </form-group>
        </div>
        <div class="col-sm">
          <form-group appearance="fill">
            <label class="descCampo" for="nombre">Nombre </label>
            <input
              type="text"
              id="nombre"
              formControlName="nombre"
              class="form-control"
              required
              placeholder="Nombre"
            />
            <!-- <div *ngIf="submitted && f['nombre'].errors" class="invalid-feedback">
            <div i18n="nombreRequerido | El nombre es requerido"*ngIf="f['nombre'].errors.required">Name is required</div>
          </div> -->
          </form-group>
        </div>
        <div class="col-sm">
          <form-group appearance="fill">
            <label for="apellido" class="descCampo">Apellido </label>
            <input
              type="text"
              id="apellido"
              formControlName="apellido"
              class="form-control"
              required
              placeholder="Apellido"
            />
            <!-- <div *ngIf="submitted && f.apellido.errors" class="invalid-feedback">
              <div  i18n="apellidoRequerido | El apellido es requerido" *ngIf="f.apellido.errors.required">Last name is requiredo</div>
          </div> -->
          </form-group>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-sm">
          <form-group appearance="fill">
            <label for="tipoDocumento">Tipo de Documento</label>
            <input
              type="text"
              id="tipoDocumento"
              required
              formControlName="tipoDocumento"
              class="form-control"
              placeholder="Tipo de Documento"
            />
          </form-group>
        </div>
        <div class="col-sm">
          <form-group appearance="fill">
            <label for="numeroDocumento">Documento</label>
            <input
              type="text"
              id="numeroDocumento"
              required
              formControlName="numeroDocumento"
              class="form-control"
              placeholder="Documento"
            />
          </form-group>
        </div>
        <div class="col-sm">
          <form-group appearance="fill">
            <label for="paisDocumento">País de Emisión</label>
            <select
              id="paisDocumento"
              formControlName="paisDocumento"
              class="form-control"
              placeholder="País de Emisión"
              [compareWith]="idComparator"
            >
              <option *ngFor="let p of paises" [ngValue]="p">
                {{p.nombre}}
              </option>
            </select>
          </form-group>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm">
          <form-group appearance="fill">
            <label for="telefono" class="descCampo">Teléfono</label>
            <input
              type="text"
              id="telefono"
              required
              formControlName="telefono"
              class="form-control"
              placeholder="Teléfono"
            />
          </form-group>
        </div>
        <div class="col-sm">
          <form-group appearance="fill">
            <label for="domicilio">Dirección</label>
            <input
              type="text"
              id="domicilio"
              formControlName="domicilio"
              required
              class="form-control"
              placeholder="Dirección"
            />
          </form-group>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm">
          <form-group appearance="fill">
            <label for="codigoPostal">Código Postal</label>
            <input
              type="text"
              id="codigoPostal"
              required
              formControlName="codigoPostal"
              class="form-control"
              placeholder="Código Postal"
            />
          </form-group>
        </div>
        <div class="col-sm">
          <form-group appearance="fill">
            <label for="ciudad">Ciudad</label>
            <input
              type="text"
              id="ciudad"
              required
              formControlName="ciudad"
              class="form-control"
              placeholder="Ciudad"
            />
          </form-group>
        </div>
        <div class="col-sm">
          <form-group appearance="fill">
            <label for="departamento">Estado</label>
            <input
              type="text"
              id="departamento"
              required
              formControlName="departamento"
              class="form-control"
              placeholder="Estado"
            />
          </form-group>
        </div>
        <div class="col-sm">
          <form-group appearance="fill">
            <label for="paisDomicilio">País</label>
            <select
              id="paisDomicilio"
              formControlName="paisDomicilio"
              class="form-control"
              placeholder="País"
              [compareWith]="idComparator"
            >
              <option *ngFor="let p of paises" [ngValue]="p">
                {{p.nombre}}
              </option>
            </select>
          </form-group>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-sm">
          <button
            class="btn btn-flat btn-accent btn-update"
            color="primary"
            (click)="confirmUpdateUser(userForm)"
          >
            Actualizar Datos
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
