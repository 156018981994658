import { SuscripcionService } from 'src/app/shared/services/suscripcion.service';
import {
  Component,
  OnInit,
  NgZone,
  Renderer2,
  ElementRef,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { KeycloakService } from 'keycloak-angular';
import { PartnerDialogComponent } from './component/partner-dialog/partner-dialog.component';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss'],
})
export class AboutUsComponent implements OnInit {

  isLoggedIn = false;
  public user!: Keycloak.KeycloakProfile;
  activeTab: 'Empresas' | 'Personas' = 'Empresas';
  esSuscriptor: boolean = false;

  constructor(
    private modalService: NgbModal,
    private ngZone: NgZone,
    private keycloakService: KeycloakService,
    private renderer: Renderer2,
    private el: ElementRef,
    private suscripcionService: SuscripcionService
  ) {
    this.keycloakService.loadUserProfile().then((u) => {
      this.user = u;
      this.checkLoginStatus();
    });
  }


  focusTab(event: any): void {
    const currentActiveElements =
      this.el.nativeElement.querySelectorAll('.active-tab');

    currentActiveElements.forEach((element: any) => {
      if (element !== event.currentTarget) {
        this.renderer.removeClass(element, 'active-tab');
      }
    });

    const targetClass = event.currentTarget.classList.contains('active-tab');
    this.renderer.addClass(event.currentTarget, targetClass);
  }
  onTabClick(tab: any) {
    this.activeTab = tab;
  }

  ngOnInit(): void {
    this.suscripcionService.isSuscriptor().subscribe((result) => {
      this.esSuscriptor = result;
      if (this.esSuscriptor) this.onTabClick('Personas')
    });
  }

  async checkLoginStatus() {
    this.isLoggedIn = await this.keycloakService.isLoggedIn();
  }
}
