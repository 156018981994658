import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Elemento } from 'src/app/shared/models/elemento.model';
import { ElementoFiltro } from 'src/app/shared/models/elementoFiltro.model';
import { ElementService } from 'src/app/shared/services/element.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PartnerDialogComponent } from 'src/app/modules/aboutus/component/partner-dialog/partner-dialog.component';
import { SuscripcionService } from 'src/app/shared/services/suscripcion.service';

@Component({
  selector: 'app-comision-detail',
  templateUrl: './comision-detail.component.html',
  styleUrls: ['./comision-detail.component.scss'],
})
export class ComisionDetailComponent {
  idElemento!: string;
  elemento!: Elemento;

  esSuscriptor: boolean = true;

  constructor(
    private elementService: ElementService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private suscripcionService: SuscripcionService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.idElemento = this.router.url.substring(
        this.router.url.lastIndexOf('/') + 1,
        this.router.url.length,
      );

      // Informaciones
      var filtroDetalle: ElementoFiltro = new ElementoFiltro();
      filtroDetalle.id = Number.parseInt(this.idElemento);
      this.elementService.getAll(0, 0, filtroDetalle, '').subscribe(data => {
        this.elemento = (data as any)['elementos'][0];
        if (this.elemento.fechaInfoInicio != null) {
          this.elemento.fechaInfoInicio = new Date(
            this.elemento.fechaInfoInicio,
          );
        }
      });
    });
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  openPartnerDialog() {
    const modalRef = this.modalService.open(PartnerDialogComponent, {
      centered: true,
      size: 'lg',
      modalDialogClass: 'person--dialog',
    });
    modalRef.componentInstance.id = this.elemento.responsable_id;
  }
}
