import { Component, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { KeycloakService } from 'keycloak-angular';
import { Elemento } from 'src/app/shared/models/elemento.model';
import { ElementoFiltro } from 'src/app/shared/models/elementoFiltro.model';
import { ElementService } from 'src/app/shared/services/element.service';
import { environment } from 'src/environments/environment';
import { SwiperContainer } from 'swiper/element';
import { SwiperOptions } from 'swiper/types';

@Component({
  selector: 'app-future-events',
  templateUrl: './future-events.component.html',
  styleUrls: ['./future-events.component.scss'],
})
export class FutureEventsComponent {
  [x: string]: any;

  constructor() {}
  img_base = environment.img_base;

  pageIndex = 0;
  desplazamiento = 0;
  limite = 50;
  totalItems = 0;

  @Input() eventosFuturos$: Elemento[] = [];

  isMobileWidth = false;

  ngOnInit() {
    if (window.screen.width < 1024) {
      // 768px portrait
      this.isMobileWidth = true;
    }

    const swiperEl = document.querySelector(
      '#swiper-container-future'
    ) as SwiperContainer;
    //@ts-ignore
    swiperEl.initialSlide = this.index;
    //@ts-ignore
    const params = {
      slidesPerView: 1,
      navigation: true,
      spaceBetween: 30,
      breakpoints: {
        300: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 2,
        },
        1360: {
          slidesPerView: 2.2,
        },
        1600: {
          slidesPerView: 3.2,
        },
      },
      injectStyles: [
        `
        swiper-container::part(swiper) {
          gap: 40px !important;
        }

        .card {
          height: fit-content;
        }

        .swiper-button-next, .swiper-button-prev {
          padding: 10px 5px 10px 12px;
          color: var(--verde-1);
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: rgba(255,255,255, .5);
          text-align: center;
        }

        .swiper-button-prev {
          padding: 10px 12px 10px 5px;
        }

        .swiper-pagination-bullet.swiper-pagination-bullet-active {
          background-color: var(--verde-1)
        }

        .swiper-horizontal {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        `,
      ],
    };
    //@ts-ignore
    Object.assign(swiperEl, params);
    swiperEl?.initialize();
  }

  private obtenerNumeroDesdeURL(url: string): number | null {
    const match = url.match(/\/(\d+)$/);
    return match ? parseInt(match[1], 10) : null;
  }

  private construirURL(numero: number): string | null {
    return numero > 0 ? `event-detail/${numero}` : null;
  }

  private irAPagina(numero: number): void {
    const url = this.construirURL(numero);
    if (url) {
      this['location'].replaceState(url);
      window.location.reload();
    }
  }
}
