import { Component, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { KeycloakService } from 'keycloak-angular';
import { Elemento } from 'src/app/shared/models/elemento.model';
import { ElementoFiltro } from 'src/app/shared/models/elementoFiltro.model';
import { ElementService } from 'src/app/shared/services/element.service';
import { SuscripcionService } from 'src/app/shared/services/suscripcion.service';
import { environment } from 'src/environments/environment';
import {
  CommunityDataAbstractService,
  communityDataToken,
  DataIniciativasType,
} from '../../../community-data';
import { CommunityPanelService } from '../../../community-panel.service';

@Component({
  selector: 'app-iniciativas',
  templateUrl: './iniciativas.component.html',
  styleUrls: ['./iniciativas.component.scss'],
})
export class CommunityPanelIniciativasComponent {
  img_base = environment.img_base;
  pageIndex = 0;
  desplazamiento = 0;
  limite = 1000;
  totalItems = 0;
  elementos$: Elemento[] = [];
  communityService = inject<CommunityPanelService>(communityDataToken);

  public filtroUI = new FormGroup({
    nombre: new FormControl(),
  });

  infoIniciativas = this.communityService.getInfo(
    'iniciativas',
  ) as DataIniciativasType;

  ngOnInit() {
    this.filtrar(false);
  }

  public async filtrar(cargarMas: boolean): Promise<void> {
    if (!cargarMas) {
      this.pageIndex = 0;
      this.desplazamiento = 0;
    }
    var filtroProyectos: ElementoFiltro = new ElementoFiltro();

    Object.keys(this.filtroUI.value).forEach((key, index) => {
      if (key.includes('nombre')) {
        (filtroProyectos as any)[key] = (this.filtroUI.value as any)[key];
      }
    });
    const data = await this.communityService.getIniciativas(
      this.limite,
      this.pageIndex,
      filtroProyectos,
    );
    this.elementos$ = [...this.elementos$, ...data.elementos];
    this.pageIndex++;
  }
}
