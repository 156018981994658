<!-- begin Proyectos e Iniciativas -->
<div class="module">
  <div class="container-fluid">
    <div class="module__header">
      <div class="module__title">
        <h3 class="mat-typography__headline mat-typography__headline--white mb-sm-0">Novedades
        </h3>
      </div>
      <a routerLinkActive="active" routerLink="news" class="link-underlined link-underlined--white">Ver
        todas las novedades</a>
    </div>
    <!-- ToDo: INés_20231128: usar campo -->
    <div class="module__items">
      <ng-container *ngFor="let elemento of elementos$; let i=index">
        <a href="/news-detail/{{elemento.id}}">
          <div class="mat-card--project" *ngIf="i<3">
            <div class="imgContainer">
              <img src="{{img_base}}{{elemento.detailImage}}">
            </div>
            <div class="information">
              <h4>{{elemento.nombre}}</h4>
              <p class="description">{{elemento.copete}}</p>
            </div>
          </div>
        </a>
      </ng-container>
    </div>
  </div>
</div>
<!-- end Proyectos e Iniciativas -->