import { OrdersPage } from './../../modules/orders/orders.page';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(private httpClient: HttpClient) {}

  public getAll(
    limite: number,
    desplazamiento: number,
    fechaInicio: NgbDateStruct | null,
    fechaFin: NgbDateStruct | null,
    rangoDias: string,
    estado: string | null,
    tipo: string | null,
  ): Observable<any> {
    let params = new HttpParams();

    if (estado == 'TODOS') {
      estado = null;
    }

    // if (tipo == 'TODOS') {
    //   tipo = null;
    // }

    if (fechaInicio != null) {
      params = params.append('fechaDesde', fechaInicio.toString());
    }
    if (fechaFin != null) {
      params = params.append('fechaHasta', fechaFin.toString());
    }

    // if (tipo != null) {
    //   params = params.append('tipoReferencia', tipo);
    // }

    if (estado != null) {
      params = params.append('estadoPago', estado);
    }

    if (desplazamiento != null) {
      params = params.append('desplazamiento', desplazamiento.toString());
    }
    if (limite != null) {
      params = params.append('limite', limite.toString());
    }

    return this.httpClient.get<any>(
      environment.api_tienda +
        'payments/public/my/tenants/' +
        environment.idStore +
        '/orders',
      { params },
    );
  }

  pagarOrdenPago(paymentOrderId: number): Observable<any> {
    return this.httpClient.post(
      environment.api_tienda +
        'payments/public/my/tenants/' +
        environment.idStore +
        '/orders/' +
        paymentOrderId +
        '/payments',
      null,
      { responseType: 'text' },
    );
  }
}
